require('normalize.css/normalize.css');
require('../scss/index.scss');


// libs
require('./libs/read-more.min.js');



// .................................................................................
// IMPORTS
// .................................................................................


// Swiper JS

import Swiper, { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';


Swiper.use([Pagination]);


// node marquee

import nodeMarquee from 'node-marquee';


// jquery-mousewheel
import 'jquery-mousewheel';


// .................................................................................
// ON LOAD
// .................................................................................


setTimeout(() => {
    
        // functions to be loaded BEFORE making page visible
        // ------------------------------------------------
    
        // // place landing at top
        // if ( $('.template-landing')[0] && !tablet) {
        //     $('body').stop().animate({scrollLeft:0}, 10, 'linear', function() { 
        //         $('body').stop().animate({scrollTop:0}, 500, 'swing');
        //      });
        // }
    
    
        loadParallax();
        updateParallax();
        loadYoutubeEmbeds();
        loadSwipers();
    
        // overlaps layouts if present
        if ( $('.overlap-layout')[0] ) {
            loadOverlap();
        }
    
        // node marquee
        nodeMarquee({
            parent: '#node-marquee'
        });
    
    
        // aos after all layout is set
        // -----------------------------------------
        AOS.init({
            once: true
        });
    
        // make page visible
        // -----------------------------------------
        $('body').removeClass('hidden');
    
    
        // animation start
        // -----------------------------------------
        window.requestAnimationFrame(animateFunction);
    
    
        // functions to be loaded AFTER making page visible
        // ------------------------------------------------
    
        // scroll direction change
        prepareScrollDirChange();
    
        // prepare for interactive windows interaction
        prepareInteractiveWindows();
        prepareSound();
    
        $('#form-audio').find('.file-custom').attr('data-before', "Sel·lecciona l'arxiu...");
        $('#form-video').find('.file-custom').attr('data-before', "Sel·lecciona l'arxiu...");
        $('.file-custom').attr('data-before', "Sel·lecciona l'arxiu...");
    
    
}, 100);

$('#form-audio').find('.file-custom').attr('data-before', "Sel·lecciona l'arxiu...");
$('#form-video').find('.file-custom').attr('data-before', "Sel·lecciona l'arxiu...");
$('.file-custom').attr('data-before', "Sel·lecciona l'arxiu...");



// .................................................................................
// RESIZE
// .................................................................................

$(window).on("resize", function(event){
    calcScreen();

    if ( $('.overlap-layout')[0] ) {
        recalcOverlap();
    }

    $('[data-iframe-video-src]').each(recalcYoutubeEmbeds);

    // scroll recheck
    scrollUpdate(event);

    // scroll direction change
    prepareScrollDirChange();

});



// .................................................................................
// SCROLL
// .................................................................................

$(window).scroll(function() {

    if ( $('.overlap-layout').length && overlaps_loaded) {
        updateOverlap();
    }

    updateParallax();

    var maxScrollTop = $(document).height() - $(window).height();

    if ($('.horizontal-desktop')[0] && $(window).width() >= 1024 ) {
        // hide / display menu
        if ($(window).scrollTop() > maxScrollTop - 600 ) {
            $('nav.menu').show();
        } else {
            $('nav.menu').hide();
        }
    }

});



// .................................................................................
// ANIMATE
// .................................................................................

function animateFunction() {
    
    // updateParallax();

    window.requestAnimationFrame(animateFunction);
}



// .................................................................................
// CALC SCREEN
// .................................................................................

let screenWidth, screenHeight, desktop, laptop, mobile, tablet, mobileSmall;

function calcScreen() {

  screenWidth = $(window).innerWidth();
  screenHeight = $(window).innerHeight();

  // screen variables will be true for current size and all superior sizes
  // eg: if screenWidth = 900 (we are using a tablet), then laptop will be true, but mobile&mobileSmall will be false

  if (screenWidth <= 1360) desktop = true; else desktop = false; 
  if (screenWidth <= 1024) laptop = true; else laptop = false; 
  if (screenWidth <= 990) tablet = true; else tablet = false; 
  if (screenWidth <= 767) mobile = true; else mobile = false; 
  if (screenWidth <= 375) mobileSmall = true; else mobileSmall = false;   
}

calcScreen();



// .................................................................................
// PARALLAX EFFECT
// .................................................................................

let parallaxArray = [];

let parallax_items = $('.deco-parallax');

function loadParallax(){

    parallax_items.each(function(i){

        let item = $(this);
    
        if ( item.hasClass('top') || item.hasClass('mid') || item.hasClass('relative')) {
            parallaxArray[i] = parseInt(item.css('top'));

        } else {
            parallaxArray[i] = parseInt(item.css('bottom'));
        }

    });
}

function updateParallax(){

    parallax_items.each(function(i){

        let item = $(this);
        let speed = parseFloat($(this).data('speed'));

        if (!speed) {
            speed = -0.1;
        }

        if ( item.hasClass('top') || item.hasClass('mid') || item.hasClass('relative')) {
            setPropretyParallax(item, speed, 'top', parallaxArray[i]);
        } else {
            setPropretyParallax(item, speed, 'bottom', parallaxArray[i]);
        }
    });
 
}
 
function setPropretyParallax(element, ratio, value, orig) {
    
    var pos;

    if ($('.horizontal-desktop')[0] && $(window).width() >= 1024 ) {
        pos = orig - ratio * ($(window).scrollTop() + $('.horizontal-desktop').scrollLeft()*0.4 - element.offset().top - element.offset().left*0.4);
    } else {
        pos = orig - ratio * ($(window).scrollTop() - element.offset().top);
    }

    pos = pos.toFixed(1);

    element.css(value, pos/10 + 'rem');

}



// .................................................................................
// OVERLAP EFFECT
// .................................................................................

let overlaps = $('.overlap-layout');
let elements_selector = '.overlap-element';   

let overlap_elems = new Array(overlaps.length).fill('');
let overlap_done = new Array(overlaps.length).fill('');
let overlap_nodes = new Array(overlaps.length).fill('');
let overlap_length = new Array(overlaps.length).fill('');
let overlap_slide = new Array(overlaps.length).fill('');
let overlap_w = new Array(overlaps.length).fill('');
let overlap_h = new Array(overlaps.length).fill('');
let overlap_top  = new Array(overlaps.length).fill('');

let overlaps_loaded = false;



// first load of overlap elements (onload)
function loadOverlap(){

    overlaps.each(function(i){

        let overlap = $(this);

        overlap_elems[i] = overlap.find(elements_selector);
        overlap_done[i] = false;

        // define distribution of elements for each different overlap
        if (overlap.hasClass('overlap-telefons-ajuda')) {

            overlap_nodes[i] = 
                // X, Y
                [[50, 12],
                [80, 35],
                [45, 64],
                [75, 86]];

            overlap_slide[i] = 
                // X variation , Y variation
                [[3, 4],
                [6, -1],
                [-6, 2],
                [3, -4]];

        } else if (overlap.hasClass('overlap-testimonis-video')) {

            overlap_nodes[i] = 
                // X, Y
                [[20, 10],
                [60, 40],
                [1, 30],
                [40, 20]];

            overlap_slide[i] = 
                // X variation , Y variation
                [[-3, 4],
                [-6, -1],
                [3, 2],
                [3, -4]];  

        } else if (overlap.hasClass('overlap-histories')) {

            overlap_nodes[i] = 
                // X, Y
                [[70, 60], // audio
                [1, 15],   // text
                [45, 1]];  // video

            overlap_slide[i] =  
                // X variation , Y variation
                [[-2, -4],  // audio
                [1, 1],  // text
                [3, 2]];   // video
        }

        overlap_length[i] = overlap_nodes[i].length;

        // initialization of overlap elements + calculation of maxwidth
        let max_width = 0;

        overlap.find(elements_selector).each(function(i){

            let el = $(this);

            // set initial z-index to elements
            el.css('z-index', 100 + overlap_length[i] - i);

            // get max width
            let el_width = el.width();
            if (el_width > max_width) {
                max_width = el_width;
            }
        });

        // initialization of wrappers
        recalcOverlap();
        overlap.css('width', overlap_w[i]  - max_width);

    });

    recalcOverlap();
    overlaps_loaded = true;
    
    // update
    updateOverlap();
    
}

function recalcOverlap(){

    overlaps.each(function(i){

        let overlap = $(this);

        overlap_w[i] = overlap.width();
        overlap_h[i] = overlap.height();
        overlap_top[i] = overlap.offset().top;
    });

}

// to be called on load and on scroll
function updateOverlap(){ 

    let overlap;

    overlaps.each(function(i){

        overlap = $(this);

        if (overlap_done[i]) {
            positionOverlap(i);

        } else if (isVisible(overlap) && !overlap_done[i]) {
            displayOverlap(i);

            positionOverlap(i);
            overlap_done[i] = true;
        }

    });

    function displayOverlap(i){

        overlap.find(elements_selector).each(function () {

            let el = $(this);

            el.css('opacity', '1');
            el.css('transform', 'translate(-50%,-50%) scale(1)');
        });
    }

    function positionOverlap(i){

        overlap.find(elements_selector).each(function (node) {

            let el = $(this);
            
            let xpos = calcScroll(overlap_nodes[i][node][0], overlap_slide[i][node][0]);
            let ypos = calcScroll(overlap_nodes[i][node][1], overlap_slide[i][node][1]);

            if(xpos > 0 && xpos < 100){
                el.css('top', ypos + '%');
                el.css('left', xpos + '%');
            }
            

        });

        function calcScroll(coord, ratio){
            if (!tablet) {
                ratio = 0;
            }
            return coord + ratio * ($(window).scrollTop() - overlap_top[i] ) / overlap_h[i];
        }

        // function randomizePos(coord){
        //     let random = (Math.random() - 0.5) * Math.random();
        //     return coord + 100 * random;
        // }
    }
}



// .................................................................................
// SWIPER
// .................................................................................


function loadSwipers(){
    const swiper_capsules = new Swiper('.swiper-capsules-event', {
        // Optional parameters
        loop: true,
        // createElements: true,

        // pagination
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        spaceBetween: 30,
        autoHeight: true

        // observer: true, observeParents: true
    });

    const swiper_quotes = new Swiper('.swiper-quotes', {
        // Optional parameters
        loop: true,
        // createElements: true,

        // pagination
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        spaceBetween: 100,
        autoHeight: true

        // observer: true, observeParents: true
    });
    
}




// .................................................................................
// YOUTUBE VIDEOS EMBEDS
// .................................................................................


function loadYoutubeEmbeds() {

    let videoIframes = $('[data-iframe-video-src]');

    videoIframes.each(function(){
        let item = $(this);
        let url = item.data('iframe-video-src');
        let iframe;
        
        if (url.includes("youtu")) {
            let id = youtube_parser(url);
            let correct_url = 'https://www.youtube.com/embed/' + id + '?modestbranding=1&showinfo=0&rel=0&disablekb=1&color=white&controls=0';
            let width = item.width();
            let height = 0.5625 * width;
            iframe = $(`<iframe src="${correct_url}" 
                                type="text/html" 
                                controls=0
                                disablekb=1
                                frameborder="0"
                                allow="fullscreen;"
                                width="${width}"
                                height="${height}"
                                class="video-iframe">
                            </iframe>`); 
        }
    
        item.append(iframe);
    
    });
}

function recalcYoutubeEmbeds() {

    let item = $(this);

    if (item.data('iframe-video-src').includes("youtu")) {

        let width = item.width();
        let height = 0.5625 * width;

        let iframe = item.find('iframe');

        iframe.attr( 'width', width );
        iframe.attr( 'height', height );

    }

}



function youtube_parser(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
}




// .................................................................................
// READ MORE BUTTONS
// .................................................................................


// Initialise

function prepareReadMore(){

    if (mobile) {
        var destroy = $readMoreJS({
            target: '.read-more',
            wordsCount: 20, // Alternatively use `charactersCount` if you wish to truncate by characters' length.
            toggle: false,
            moreLink: 'Llegir més',
            lessLink: 'Llegir menys',
            linkClass: 'rm-link-classname'
        });
    }
}
prepareReadMore();




// .................................................................................
// WINDOWS' INTERACTION: "interactive-windows.js"
// .................................................................................


// Minimizing 
// ----------

$('.js-interactive-windows .cross').on('click', function(event){

    event.stopPropagation();
    event.preventDefault();

    let window = $(this).parent().parent();
    toggleWindowState(window);

});

$('.messages-pop-up .cross').on('click', function(){
    $(this).parent().remove(); 
    var elem = $('#formulari');
    $.scrollTo(elem.left, elem.top);

});

$('.warning-pop-up .cross, .captcha-pop-up .cross, .xtra-pop-up .cross, .required-pop-up .cross').on('click', function(){
    $(this).parent().slideToggle(); 
});


function toggleWindowState(window) {

    let content = window.find('.content');

    if (content.hasClass('logo')) {
        window.toggleClass('closed');

    } else {

        if (window.hasClass('minimized')) {
            content.slideDown();

        } else {
            content.slideUp();
            if ( window.hasClass('playing')) {
                stopPlaying(window);
            }
        }
    
        window.toggleClass('minimized');
    }
}



// Refreshing
// ----------

$('.js-interactive-windows .refresh').on('click', function(event){

    event.stopPropagation();

    let button = $(this);
    let window = button.parent().parent();
    let id = window.attr('data-id');
    let type = window.data('type');
    let refresh = window.find('.refresh');

    setNewTopmost(window);

    refresh.addClass('turning');

    $.ajax({
        type: 'GET',
        url: 'refresh',
        data: {
            'id': id,
        },
        dataType: 'json',
        success: function(data){

            //  actions of each particular type of story
            if (type == 'video') {
                refreshVideo(window, data);
            } else if (type == 'audio') {
                refreshAudio(window, data);
            } else if (type == 'text') {
                refreshText(window, data);
            }

        }
    });

    function refreshCommon(window, json){

        // set id + SEO TRACKING
        window.attr('data-id', json.id );
        updateSEOTracking(window, json.id);

        // set name and age
        let nom = json.nom[0].toUpperCase() + json.nom.toLowerCase().slice(1);
        window.find('.title').text( `${nom}, ${json.edat}` );

        // stop turning wheel
        window.find('.refresh').removeClass('turning');

        // deploy if minimized
        if ( window.hasClass('minimized') ) {
            toggleWindowState(window);
        }

    }

    function updateSEOTracking(window,id) {

        let target = window.find('.content');
        let item = target.attr('id'); 
        let elem = window.find('#' + item);
        // console.log(elem);

        let type = item.substring(0, 3);
        
        if (item.slice(3,8) === '_TST_') { // examples 'VID_TST_XXXX' 'TXT_TST_XXXX' 'AUD_TST_XXXX'
            if(type == 'AUD'){
                elem.attr('id', item.slice(0,8) + id);
                elem.removeClass(item);
                elem.find('img').removeClass(item);
                elem.addClass(item.slice(0,8) + id);
                elem.find('img').addClass(item.slice(0,8) + id);
                elem.addClass('content audio')
                
            } else if (type == 'TXT'){
                elem.attr('id', item.slice(0,8) + id);
            } else if (type == 'VID'){
                elem.attr('id', item.slice(0,8) + id);
                elem.find('img').removeClass(item);
                elem.find('img').addClass(item.slice(0,8) + id);
            }
            
        }
        
    }        

    function refreshVideo(window, json){

        // doc elements
        let content = window.find('.content');
        let preview = window.find('.preview');
        let video = window.find('video');

        // info from json
        let preview_url = '/media/' + json.imatge;
        let video_url = '/media/' + json.video;

        if (window.hasClass('playing')) {

            stopPlaying(window, false);
            preview.css('opacity',1);

            content.slideUp( function(){

                preview.attr('src', preview_url );

                // change video source
                video.attr('src', video_url);
                video.attr('data-src', video_url );

                // have some time to load it
                setTimeout(function(){

                    preview.css('opacity',1); // se sale por culpa del delay

                    content.slideDown( function() {
                        video.css('opacity',1);
                        lastActions();
                        refreshCommon(window, json);
                        startPlaying(window, false);  

                    });


                }, 200);

                // preview.css('filter','invert(1)');

            });    

        } else {

            content.slideUp( function(){

                preview.attr('src', preview_url );
                video.attr('data-src', video_url );

                content.find('.play').fadeIn();

                // have some time to load the image
                setTimeout(function(){
                    preview.css('opacity',1);
                    content.slideDown( function(){
                        refreshCommon(window, json);
                    });
                }, 200);
            });
        }
    }

    function refreshAudio(window, json){

        let audio = window.find('.audio');
        let audio_src = '/media/' + json.audio ;

        stopSound (audio);
        registerNewSound( audio, audio_src );

        lastActions();
        refreshCommon(window, json);
    }

    function refreshText(window, json){

        window.find('.text').text( json.text );
        refreshCommon(window, json);

    }

    function lastActions(){

        //  actions to do after content loading
        if (window.hasClass('playing')) {
            startPlaying(window);    
        }

        if ( window.hasClass('closed') ) {
            window.removeClass('closed');
        }
    }

});




// Putting on top + Playing content
// --------------------------------

$('.js-interactive-windows .js-window .play').on('click', function(event){

    event.stopPropagation();

    let window = $(this).parent().parent();

    setNewTopmost(window);
    startPlaying(window);   
});

$('.js-interactive-windows .js-window').on('click', function(i){

    let window = $(this);

    if ( window.hasClass('topmost') && !window.hasClass('closed') ) {

        if (window.hasClass('playing')) {
            stopPlaying(window);    
        } else {
            startPlaying(window);    
        }

    } else {
        setNewTopmost(window);
    }
});

function setNewTopmost(window){

    let sibs = window.siblings();

    // console.log(sibs);

    sibs.each(function(){
        stopPlaying($(this))
    }); 

    sibs.removeClass('topmost');
    window.addClass('topmost');
}



// auxiliary functions
// -------------------


function startPlaying(window){

    let content = window.find('.content');

    if (content.hasClass('video')) {

        let video = content.find('video');
        let url = video.attr('data-src');
        let play = content.find('.play'); 
        let preview = content.find('.preview'); 

        if ( video.attr('src') !== url ) {
            play.addClass('loading');
            video.attr('src',url);
            video.get(0).load()
            play.fadeOut();

            video.on('loadeddata',function(){
                preview.css('opacity',0);
                video.css('opacity',1);
            });

        } else {
            video.css('opacity',1);
            preview.css('opacity',0);
            video.get(0).play();
        }

        // setTimeout(function(){
        //     // video.attr('controls',true);
        //     preview.css('opacity',0);
        //     video.get(0).play();
        // }, 500);

        window.addClass('playing');

    } else if (content.hasClass('audio')) {
        
        playSound (content);
        content.css('opacity',0.75);

        window.addClass('playing');

    } // no action for 'text' and 'logo'

}

function stopPlaying(window){

    let content = window.find('.content');

    if (content.hasClass('video')) {

        let video = content.find('video');
        let play = content.find('.play');  

        video.get(0).pause()

        // video.attr('controls',false);

        window.removeClass('playing');


    } else if (content.hasClass('audio')) {
        
        // AUDIO LOAD
        stopSound (content);
        content.css('opacity',1);

        window.removeClass('playing');


    } // no action for 'text' and 'logo'
}



// initialization (on load)
// --------------

let interactiveWindowsArray = [];

function prepareInteractiveWindows() {

    // save z-index
    $('.js-interactive-windows .js-window').each(function(){
        interactiveWindowsArray.push($(this).css('z-index'));
    });

    // initialize topmost window
    $('.js-interactive-windows').each(function(){
        let layout = $(this);
        if (!layout.find('.topmost')[0]) {
            layout.children().first().addClass('topmost');
        }
    });

}

// .................................................................................
// SOUND
// .................................................................................

var historia_audio;

function prepareSound() {
    historia_audio = new Audio($('.window .content.audio').attr('data-src'));
}


function registerNewSound ( content , src ) {

    historia_audio = new Audio(src);
    
    if (content.parent().hasClass('playing')) {

        setTimeout(function(){
            playSound (content);
        },400);
    }
}

function playSound (content) {
    historia_audio.play();
}

function stopSound (content) {
    historia_audio.pause();
}

// function maxSoundIndex() {

//     let max = 0;

//     $('.window .content.audio').each(function (index) {
//         let number_id = parseInt($(this).attr('data-sound-id').slice(6));
//         if (number_id > max)
//             max = number_id;
//     });

//     return max;

// }

// .................................................................................
// FORM // COMPARTEIX HISTORIES
// .................................................................................

$('.js-form-triggers .button').on('click', function(){

    var button = $(this);
    var id = button.data('id');
    var type = button.data('type');
    var target = $('#' + id);
    let form_displays = $('.js-form-display');

    if($('.warning-pop-up').length){

        $('.warning-pop-up').slideToggle();
        $('#accepted_warning').data('id', id);
        $('#accepted_warning').data('type', type);

    } else if (!button.hasClass('active')) {

        // disable all buttons
        $('.trigger-buttons').find(".button").siblings().removeClass('active');
        $('.trigger-buttons').find(".button").siblings().addClass('disabled');

        // enable selected only
        $('.trigger-buttons').find("[data-type='" + type + "']").addClass('active');
        $('.trigger-buttons').find("[data-type='" + type + "']").removeClass('disabled');

        // reset form
        form_displays.hide();

        // display selected form
        target.slideDown(600,function(){
            target.css('display','block'); //bug that made elements inline
        });

        // change value of form hidden field for historia_type
        $('#form-type').val(type);

        $('#form-video').find('input').val('');
        $('#form-audio').find('input').val('');
        $('#form-text').find('input').val('');

        $('#audio_input').css('display', 'none');
        $('#video_input').css('display', 'none');

    }
    

});

$('#accepted_warning').on('click', function() {

    var button = $(this);
    var id = button.data('id');
    var target = $('#' + id);
    var type = button.data('type');
    let form_displays = $('.js-form-display');
    $('.warning-pop-up').remove();

    // // hide intro text to gain space in desktop version
    // $('#text-to-hide').addClass('text-hidden');
    // console.log('yes');

    if (!button.hasClass('active')) {

        // disable all buttons
        $('.trigger-buttons').find(".button").siblings().removeClass('active');
        $('.trigger-buttons').find(".button").siblings().addClass('disabled');

        // enable selected only
        $('.trigger-buttons').find("[data-type='" + type + "']").addClass('active');
        $('.trigger-buttons').find("[data-type='" + type + "']").removeClass('disabled');

        // reset form
        form_displays.hide();

        // display selected form
        target.slideDown(600,function(){
            target.css('display','block'); //bug that made elements inline
        });

        // change value of form hidden field for historia_type
        $('#form-type').val(type);
        $(this).parent().parent().slideToggle(); 

        $('#form-video').find('input').val('');
        $('#form-audio').find('input').val('');
        $('#form-text').find('input').val('');

    }
});

$('input[type="file"]').change(function(e) {
    var fileName = e.target.files[0].name;
    $(this).next().attr('data-before', fileName);
      // Inside find search element where the name should display (by Id Or Class)
});


  
  


$('#form-submit').on('click', function(){

    let active_button = $('.js-form-triggers .button.active');
    active_button.siblings().val('');

    if ( $('#form-video input').val() || $('#form-audio input').val() || $('#form-text textarea').val() ) {

        let form_valid = true;

        $('*[required]').each(function(){
            var field = $(this);

            field.removeClass('js-form-error');

            if (!field.val() && field.attr('id') !== 'id_captcha_1') {
                form_valid = false;
                field.addClass('js-form-error');
            }
        });

        $('input.checkbox').each(function(){
            var field = $(this);

            field.parent().removeClass('js-form-error');

            if(!field.is(':checked')){
                form_valid = false;
                field.parent().addClass('js-form-error');
            }
        })

        if (form_valid) {
            $('.captcha-pop-up').slideToggle(); //load captcha + definitive submit 
        } else {
            $('.required-pop-up').fadeIn();
        }

        // // let form = $('#form-histories'); 
        // // form.validate({
        // //     ignore: "#id_captcha_1"
        // //  });
        // // if (form.valid()) {
        // if (form_valid) {
            
        // } else {
        //     $('.messages-pop-up').slideToggle();
        // }
            
    } else {
        $('.xtra-pop-up').fadeIn();
    }
});

$('.js-captcha-refresh').click(function () {
    $.getJSON("/captcha/refresh/", function (result) {
        $('.captcha').attr('src', result['image_url']);
        $('#id_captcha_0').val(result['key'])
    });
});

document.addEventListener('DOMContentLoaded', (ev) => {
    let form = document.getElementById('form-histories');
    let input_video = document.getElementById('REC_VID');
    let input_audio = document.getElementById('REC_AUD');

    input_video.addEventListener('change', (ev) => {
        let video = document.getElementById('video_input');
        video.src = window.URL.createObjectURL(input_video.files[0]);
        document.getElementById('audio_input').style.display = 'none';
        document.getElementById('video_input').style.display = 'block';
    });

    input_audio.addEventListener('change', (ev) => {
        let audio = document.getElementById('audio_input');
        audio.src = window.URL.createObjectURL(input_audio.files[0]);
        document.getElementById('audio_input').style.display = 'block';
        document.getElementById('video_input').style.display = 'none';
    });

});




// .................................................................................
// TEST
// .................................................................................

$('#test .js-test').on('click', function(){

    let target = $(this).parent().parent();
    target.css('display', 'none');

});




// .................................................................................
// LEGAL ANCHORS GENERATOR
// .................................................................................


$('.template-legal').each(function(){

    let headers = $('h2').each(function(index){
        let number = index + 1;
        $(this).attr('id','seccio'+ number);
    });

    if(window.location.hash) {
        var elem = $('#' + window.location.hash.replace('#', ''));
         $.scrollTo({
            top: elem.top,
            left: elem.left,
            behavior: 'smooth'
        });
    }

});






// .................................................................................
// SCROLL DIRECTION CHANGE
// .................................................................................


function prepareScrollDirChange(){
    $('body').on('mousewheel DOMMouseScroll', scrollUpdate);
    $('.horizontal-desktop').on('scroll', updateParallax);

}

function scrollUpdate(event){

    if ($('.horizontal-desktop')[0] && $(window).width() >= 1024 ) {

        var delta = Math.max( -1 , Math.min(
                1, (event.originalEvent.wheelDelta || -event.originalEvent.detail) ));

        var maxScrollTop = $(document).height() - $(window).height();

        // make sure we are in the bottom of the page if we have scrolled horizontally
        if ($(window).scrollLeft() > 0 ) {
            $(window).scrollTop(maxScrollTop);
        }

        // when approaching bottom
        // - go to very bottom 
        // - start scrolling left 
        // - remove scrollbar
        if ($(window).scrollTop() >= maxScrollTop - 5 ) {
            $('body').css('overflow-y', 'hidden');
            $('.horizontal-desktop').scrollLeft( $('.horizontal-desktop').scrollLeft() - ( delta * 12 ) );
            $(window).scrollTop(maxScrollTop);
        } 

        // hide / display menu
        if ($(window).scrollTop() > maxScrollTop - 600 ) {
            $('nav.menu').show();
        } else {
            $('nav.menu').hide();
        }

        // replace scrollbar if we have scrolled all the way left
        setTimeout(function(){
            if ( $('.horizontal-desktop').scrollLeft() == 0) {
                $('body').css('overflow-y', 'auto');
                $(window).scrollTop($(window).scrollTop() -  ( delta * 5 ) );
            }
        }, 50);

        // event.preventDefault();

        updateParallax();

    }
}

$('.section-histories .content.text').on('mousewheel DOMMouseScroll', function(event){
    event.stopPropagation();
});



// .................................................................................
// UTILS --> VISIBILITY
// .................................................................................

function isVisible(element) {

    if (element) {
        
        let parent_data = element.data('parent');

        if (parent_data) {
            let parent = document.querySelector(parent_data);
            const { bottom, height, top } = element[0].getBoundingClientRect();
            const containerRect = parent.getBoundingClientRect();
        
            return top <= containerRect.top ? containerRect.top - top <= height : bottom - containerRect.bottom <= height;

        } else {
            var top_of_element = element.offset().top;
            var bottom_of_element = element.offset().top + $(window).outerHeight();
            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            var top_of_screen = $(window).scrollTop();
            
            return (bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element);
        }

    } else {

        return false;
    }
}


// function listenUntilVisible(element, callback){

//     let parent_data = element.data('parent');

//     if (parent_data) {
//         parent = document.querySelector(parent_data);
//     } else {
//         parent = window;
//     }

//     function selfDestructListener(){
//         if (isVisible(element)) {
//             parent.removeEventListener("scroll", selfDestructListener);   
//             callback(element);
//         }
//     }

//     parent.addEventListener("scroll", selfDestructListener);

// };

// function runWhenVisible(element, callback){

//     if (isVisible(element)) {
//         callback(element);
//     } else {
//         listenUntilVisible(element, callback);
//     };

// }


// .................................................................................
// UTILS --> EVEN HEIGHTS OF SEVERAL ELEMENTS
// .................................................................................


function recalcEvenHeights( kids_selector, container_selector = window ) {
    
    let container = $( container_selector );
    let kids = container.find( kids_selector );
    let maxH = 0;

    kids.each(function(index){

        // $(this).css('transition','height 1s ease');
        $(this).css('height','fit-content');

        // let height = $(this)[0].scrollHeight;
        let height = $(this).height();

        if ( height > maxH ) {
            maxH = height;
        }
    });

    kids.each(function(){
        $(this).height(maxH);
    });
}
